import * as React from "react";
export default function Footer() {
    return (React.createElement("footer", { className: "footer" },
        React.createElement("div", { className: "container medium-container" },
            React.createElement("div", { className: "footer__message" },
                "\u0415\u0441\u043B\u0438 \u0441\u0435\u0440\u0432\u0438\u0441 \u0440\u0430\u0431\u043E\u0442\u0430\u0435\u0442 \u043D\u0435\u043A\u043E\u0440\u0440\u0435\u043A\u0442\u043D\u043E \u0438\u043B\u0438 \u043D\u0430\u00A0\u0441\u0430\u0439\u0442\u0435 \u043D\u0435\u043F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F, \u043D\u0430\u043F\u0438\u0448\u0438\u0442\u0435 \u0432\u00A0\u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443\u00A0",
                React.createElement("a", { href: "https://t.me/apo_help", className: "text-link" }, "@apo_help")),
            React.createElement("div", { className: "footer__message" },
                "\u0410\u00A0\u0435\u0449\u0435 \u0443\u00A0\u043D\u0430\u0441 \u0435\u0441\u0442\u044C ",
                React.createElement("a", { href: "https://wiki.yandex.ru/homepage/faq---hr/", className: "text-link", target: "_blank" }, "\u0432\u0438\u043A\u0438"),
                ", \u0433\u0434\u0435\u00A0\u0441\u043E\u0431\u0440\u0430\u043D\u044B \u043E\u0441\u043D\u043E\u0432\u043D\u044B\u0435 \u0432\u043E\u043F\u0440\u043E\u0441\u044B \u0438\u00A0\u0438\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u0438 \u043F\u043E\u00A0\u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u043D\u0438\u044E \u0441\u0435\u0440\u0432\u0438\u0441\u0430"))));
}
