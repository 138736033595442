import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSAvatar, GetArraysIntersection, GlobalContext, Greeting } from '../../functions';
import UserAPI from '../../api/request/user/api';
import { IUserTemplate } from '../../api/interfaces/entity/User';
import { PaymentsAPI } from '../../api/request/payments/api';
import DocumentsAPI from '../../api/request/documents/api';
import { JobsAPI } from '../../api/request/jobs/api';
export default function Index() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState(IUserTemplate), user = _b[0], setUser = _b[1];
    var _c = useState(0), badDocumentsCount = _c[0], setBadDocumentsCount = _c[1];
    var _d = useState(0), notSeenPaymentsCount = _d[0], setNotSeenPaymentsCount = _d[1];
    var _e = useState([]), adminPages = _e[0], setAdminPages = _e[1];
    var _f = useState(false), isWatcher = _f[0], setIsWatcher = _f[1];
    useEffect(function () {
        startLoading();
        Promise.all([
            UserAPI.GetLoggedUser()
                .then(function (res) {
                setUser(res.user);
                setAdminPages(res.admin_pages);
            }),
            DocumentsAPI.GetBadDocumentsCount()
                .then(function (count) { return setBadDocumentsCount(count); }),
            PaymentsAPI.GetNotSeenPaymentsCount()
                .then(function (count) { return setNotSeenPaymentsCount(count); }),
            JobsAPI.IsLoggedUserWatcher()
                .then(function (isWatcher) { return setIsWatcher(isWatcher); }),
        ]).finally(function () {
            finishLoading();
        });
    }, []);
    return (React.createElement("div", { className: "index-page" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "index-page__greeting" },
                React.createElement("div", { className: "index-page__title-text" },
                    React.createElement(Greeting, null),
                    ", ",
                    user.first_name + (user.patronymic ? ' ' + user.patronymic : ''),
                    "!")),
            React.createElement("div", { className: "index-page__services" },
                React.createElement(Link, { to: "/personal", className: "index-page__service-item index-page__service-item--personal" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper", style: { backgroundImage: CSSAvatar(user.avatar || null) } }),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u041B\u0438\u0447\u043D\u044B\u0435",
                        React.createElement("br", null),
                        "\u0434\u0430\u043D\u043D\u044B\u0435")),
                React.createElement(Link, { to: "/documents", className: "index-page__service-item index-page__service-item--documents" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "document_scanner"),
                        React.createElement("div", { className: "index-page__service-notification", hidden: badDocumentsCount === 0 },
                            React.createElement("span", null, badDocumentsCount)),
                        React.createElement("div", { className: "index-page__service-notification index-page__service-notification--good", hidden: badDocumentsCount > 0 },
                            React.createElement("span", { className: "material-symbols-outlined" }, "done"))),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u041C\u043E\u0438",
                        React.createElement("br", null),
                        "\u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B")),
                React.createElement(Link, { to: "/faq", className: "index-page__service-item index-page__service-item--faq" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "live_help")),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u0427\u0430\u0441\u0442\u044B\u0435",
                        React.createElement("br", null),
                        "\u0432\u043E\u043F\u0440\u043E\u0441\u044B")),
                React.createElement(Link, { to: '/structure/' + (user.position_objects.length ? user.position_objects[0]['organisation_main_team_id'] : ''), className: "index-page__service-item index-page__service-item--tree" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "account_tree")),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u0421\u0442\u0440\u0443\u043A\u0442\u0443\u0440\u0430",
                        React.createElement("br", null),
                        "\u043E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u0438")),
                React.createElement("a", { href: "https://\u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0438.\u0430\u043F\u043E.\u0440\u0444/", className: "index-page__service-item index-page__service-item--personal" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper", style: { backgroundImage: 'url("/img/apo-team.png")' } }),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u0412\u0430\u043A\u0430\u043D\u0441\u0438\u0438",
                        React.createElement("br", null),
                        "\u0410\u041F\u041E"))),
            React.createElement("div", { className: "index-page__subtitle", hidden: !GetArraysIntersection(adminPages, ['documents', 'mailing', 'payments/create', 'payments/business', 'payments/hr', 'db',
                    'jobs/create', 'jobs/approve']).length && !isWatcher },
                React.createElement("div", { className: "index-page__title-text" }, "\u0410\u0434\u043C\u0438\u043D\u0441\u043A\u0438\u0435 \u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E\u0441\u0442\u0438")),
            React.createElement("div", { className: "index-page__services" },
                React.createElement(Link, { hidden: !~adminPages.indexOf('documents'), to: "/admin/documents", className: "index-page__service-item index-page__service-item--admin-documents" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "task")),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435",
                        React.createElement("br", null),
                        "\u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u043E\u0432")),
                React.createElement(Link, { hidden: !~adminPages.indexOf('mailing'), to: "/admin/mailing", className: "index-page__service-item index-page__service-item--admin-mailing" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "forward_to_inbox")),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u0421\u0434\u0435\u043B\u0430\u0442\u044C",
                        React.createElement("br", null),
                        "\u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443")),
                React.createElement(Link, { hidden: !~adminPages.indexOf('payments/create'), to: "/admin/payments/create", className: "index-page__service-item index-page__service-item--admin-payments-create" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "add_card")),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u0421\u043E\u0437\u0434\u0430\u0442\u044C",
                        React.createElement("br", null),
                        "\u0432\u044B\u043F\u043B\u0430\u0442\u0443")),
                React.createElement(Link, { hidden: !~adminPages.indexOf('payments/business'), to: "/admin/payments/business", className: "index-page__service-item index-page__service-item--admin-payments-business" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "credit_score")),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u0423\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435",
                        React.createElement("br", null),
                        "\u0432\u044B\u043F\u043B\u0430\u0442\u00A0(\u0431\u0438\u0437\u043D\u0435\u0441)")),
                React.createElement(Link, { hidden: !~adminPages.indexOf('payments/hr'), to: "/admin/payments/hr", className: "index-page__service-item index-page__service-item--admin-payments-hr" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "credit_score")),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u0423\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435",
                        React.createElement("br", null),
                        "\u0432\u044B\u043F\u043B\u0430\u0442\u00A0(HR)")),
                React.createElement(Link, { hidden: !~adminPages.indexOf('jobs/create'), to: "/admin/jobs", className: "index-page__service-item index-page__service-item--jobs-create" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "background_replace")),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435",
                        React.createElement("br", null),
                        "\u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0439")),
                React.createElement(Link, { hidden: !isWatcher, to: "/admin/jobs/watch", className: "index-page__service-item index-page__service-item--jobs-watch" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" },
                            React.createElement("span", { className: "material-symbols-outlined" }, "tv_signin"))),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u041D\u0430\u0431\u043B\u044E\u0434\u0435\u043D\u0438\u0435",
                        React.createElement("br", null),
                        "\u0437\u0430\u00A0\u0432\u0430\u043A\u0430\u043D\u0441\u0438\u044F\u043C\u0438")),
                React.createElement(Link, { hidden: !~adminPages.indexOf('jobs/create'), to: "/admin/jobs/cvs", className: "index-page__service-item index-page__service-item--jobs-cv" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "contact_mail")),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u041F\u043E\u043B\u0443\u0447\u0435\u043D\u043D\u044B\u0435",
                        React.createElement("br", null),
                        "\u0440\u0435\u0437\u044E\u043C\u0435")),
                React.createElement(Link, { hidden: !~adminPages.indexOf('jobs/approve'), to: "/admin/jobs/approve", className: "index-page__service-item index-page__service-item--jobs-approve" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "cinematic_blur")),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u0423\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435",
                        React.createElement("br", null),
                        "\u043D\u043E\u0432\u0435\u043D\u044C\u043A\u0438\u0445 \u043D\u0430\u00A0\u0422\u0414")),
                React.createElement("a", { href: "/api/redirect/db", hidden: !~adminPages.indexOf('db'), className: "index-page__service-item index-page__service-item--admin-db" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "dns")),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u041F\u0440\u044F\u043C\u043E\u0439",
                        React.createElement("br", null),
                        "\u0434\u043E\u0441\u0442\u0443\u043F\u00A0\u043A\u00A0\u0411\u0414")),
                React.createElement(Link, { hidden: !~adminPages.indexOf('db'), to: "/admin/dbmonitor", className: "index-page__service-item index-page__service-item--admin-dbmonitor" },
                    React.createElement("div", { className: "index-page__service-icon-wrapper" },
                        React.createElement("span", { className: "material-symbols-outlined index-page__service-icon" }, "browse_activity")),
                    React.createElement("div", { className: "index-page__service-title" },
                        "\u041C\u043E\u043D\u0438\u0442\u043E\u0440",
                        React.createElement("br", null),
                        "\u0431\u0430\u0437\u044B \u0434\u0430\u043D\u043D\u044B\u0445"))))));
}
