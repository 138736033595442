import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { GlobalContextProvider } from "./functions";
import App from "./App";
import "normalize.css";
import "./style/main.less";
var app = (React.createElement(BrowserRouter, null,
    React.createElement(GlobalContextProvider, null,
        React.createElement(App, null))));
ReactDOM.render(app, document.getElementById('root'));
