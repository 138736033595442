var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { createContext, useEffect, useRef, useState } from 'react';
import LoginAPI from './api/request/login/api';
export var GlobalContext = createContext({
    loading: false,
    headerUpdater: 1,
    startLoading: function () { },
    finishLoading: function () { },
    headerUpdateUser: function () { }
});
export var EMAIL_CHECK_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
// export const EMAIL_CHECK_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export var PHONE_CHECK_REGEXP = /^\+7[0-9]{10}$/;
export var VK_CHECK_REGEXP = /^[a-zA-Z0-9_.-]+$/;
export var TG_CHECK_REGEXP = /^[a-zA-Z0-9_]+$/;
export var DATE_CHECK_REGEXP = /^(19|20)\d{2}-\d{2}-\d{2}$/;
export function GlobalContextProvider(_a) {
    var children = _a.children;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(1), headerUpdater = _c[0], setHeaderUpdater = _c[1];
    var startLoading = function () { return setLoading(true); };
    var finishLoading = function () { return setLoading(false); };
    var headerUpdateUser = function () { return setHeaderUpdater(headerUpdater + 1); };
    return (React.createElement(GlobalContext.Provider, { value: { loading: loading, startLoading: startLoading, finishLoading: finishLoading, headerUpdater: headerUpdater, headerUpdateUser: headerUpdateUser } }, children));
}
export var pagesWithoutEssentials = ["/auth", "/jobs"];
export var publicPages = ["/jobs"];
export function CheckIfPageWithoutEssentials(href) {
    for (var _i = 0, pagesWithoutEssentials_1 = pagesWithoutEssentials; _i < pagesWithoutEssentials_1.length; _i++) {
        var page = pagesWithoutEssentials_1[_i];
        if (href.startsWith(page)) {
            return true;
        }
    }
    return false;
}
export function CheckIfPageIsPublic(href) {
    for (var _i = 0, publicPages_1 = publicPages; _i < publicPages_1.length; _i++) {
        var page = publicPages_1[_i];
        if (href.startsWith(page)) {
            return true;
        }
    }
    return false;
}
export function CheckedLoggedIn(mustBeLoggedIn) {
    LoginAPI.IsLoggedIn()
        .then(function (isLoggedIn) {
        if (isLoggedIn && !mustBeLoggedIn) {
            location.href = '/';
        }
        else if (!isLoggedIn && mustBeLoggedIn) {
            location.href = '/auth/login';
        }
    });
}
export function CSSAvatar(avatar) {
    if (avatar && typeof avatar === 'string') {
        return "url('".concat(avatar, "')");
    }
    return "url('/img/man-avatar.png')";
}
export function RussianNoun(n, nouns) {
    if (n % 1) {
        return nouns[3] || nouns[1];
    }
    var d1 = n % 10;
    var d2 = Math.floor(n / 10) % 10;
    if (d2 === 1) {
        return nouns[2];
    }
    else if (~[2, 3, 4].indexOf(d1)) {
        return nouns[1];
    }
    else if (d1 === 1) {
        return nouns[0];
    }
    return nouns[2];
}
export function CropText(text, n) {
    if (text.length < n) {
        return text;
    }
    while (text[n] !== ' ' && n > 0) {
        --n;
    }
    return text.slice(0, n) + '...';
}
export function FileColor(filename) {
    try {
        var filenameSplit = filename.split('.');
        var filenameFinallySplit = filenameSplit[filenameSplit.length - 2].split('-');
        var number = parseInt(filenameFinallySplit[filenameFinallySplit.length - 1], 10);
        return number % 256;
    }
    catch (e) {
        return 0;
    }
}
export function BirthdayFormat(date) {
    var dateSplit = date.split('-');
    return parseInt(dateSplit[1], 10) + ' ' + ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'][parseInt(dateSplit[0], 10) - 1];
}
export function DateFormat(date) {
    var dateSplit = date.split('-');
    return dateSplit[2] + '.' + dateSplit[1] + '.' + dateSplit[0];
}
export function Greeting() {
    var now = new Date();
    if (now.getHours() < 6) {
        return 'Доброй ночи';
    }
    if (now.getHours() < 12) {
        return 'Доброе утро';
    }
    if (now.getHours() < 18) {
        return 'Добрый день';
    }
    return 'Добрый вечер';
}
export function SinceFormat(date) {
    var dateSplit = date.split('-');
    var year = parseInt(dateSplit[0], 10);
    var month = parseInt(dateSplit[1], 10) - 1;
    var today = new Date();
    var m = today.getMonth();
    var y = today.getFullYear();
    var totalMonthsWhenStart = year * 12 + month;
    var totalMonthsNow = y * 12 + m;
    var monthsDiff = totalMonthsNow - totalMonthsWhenStart;
    var mDiff = monthsDiff % 12;
    var yDiff = Math.floor(monthsDiff / 12);
    var monthsText = mDiff + ' ' + RussianNoun(mDiff, ['месяц', 'месяца', 'месяцев']);
    var yearsText = yDiff + ' ' + RussianNoun(yDiff, ['год', 'года', 'лет']);
    return "".concat(yDiff ? yearsText : '', " ").concat(mDiff ? monthsText : '', " (\u0441 ").concat(['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'][month], " ").concat(year, ")");
}
export function FormatPhoneNumber(phoneNumber) {
    var pattern = /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
    return phoneNumber.split(' ').join('').replace(pattern, '+7 $2 $3-$4-$5');
}
export function NormalizePhoneNumber(phoneNumber) {
    var pattern = /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
    return phoneNumber.split(' ').join('').replace(pattern, '+7$2$3$4$5');
}
export function CreateFormDataFromObject(object) {
    var formData = new FormData();
    if (!object || typeof object !== 'object') {
        return formData;
    }
    for (var key in object) {
        if (object.hasOwnProperty(key)) {
            formData.append(key, object[key]);
        }
    }
    return formData;
}
// export interface Person {
//     id: number,
//
//     first_name: string,
//     last_name: string,
//     patronymic: string,
//
//     avatar: string | null,
//     raw_avatar: string | null,
//     gender: number,
//
//     tg_username: string,
//     tg_username_original_case: string,
//
//     vk_nickname: string | null,
//     email: string | null,
//
//     city: string,
//     university: string,
//     contract_type: string,
//     phone: string,
//
//     positions: number[],
//     position_objects: PositionInterface[],
//     position_class_ids: number[],
//     in_company_since: string | null,
//
//     organisations: string,
//
//     not_found: boolean,
//     deleted: boolean,
//     hidden: boolean,
//     is_ex: boolean,
//     is_signed_up: boolean,
//
//     birthday: string,
//     is_birthday: boolean,
//     date_of_birth: string,
// }
// export function PersonTemplate(object?: object): IUser {
//     const template: IUser = IUserTemplate();
//
//     for (let key in object) {
//         if (object.hasOwnProperty(key)) {
//             template[key] = object[key];
//         }
//     }
//
//     return template;
// }
export function ChangeKeyboard(str) {
    var k1 = "qwertyuiop[]\\asdfghjkl;'zxcvbnm,/QWERTYUIOP{}|ASDFGHJKL:\"ZXCVBNM<>";
    var k2 = "йцукенгшщзхъёфывапролджэячсмитьбюЙЦУКЕНГШЩЗХЪЁФЫВАПРОЛДЖЭЯЧСМИТЬБЮ";
    var keyboard1 = k1 + k2;
    var keyboard2 = k2 + k1;
    var res = '';
    for (var i = 0, max = str.length; i < max; i++) {
        if (~keyboard1.indexOf(str[i])) {
            res += keyboard2[keyboard1.indexOf(str[i])];
        }
        else {
            res += str[i];
        }
    }
    res = res.replace(/[\[\]:;{}<>,./?\\|"']/g, '');
    return res;
}
export function UseDebouncedFunction(func, delay, cleanUp) {
    if (cleanUp === void 0) { cleanUp = false; }
    var timeoutRef = useRef();
    function clearTimer() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = undefined;
        }
    }
    useEffect(function () { return (cleanUp ? clearTimer : undefined); }, [cleanUp]);
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        clearTimer();
        timeoutRef.current = setTimeout(function () { return func.apply(void 0, args); }, delay);
    };
}
export function DefaultSearch(needle, haystack) {
    if (!needle) {
        return true;
    }
    var searchString1 = needle.trim().toLowerCase();
    var searchString2 = ChangeKeyboard(searchString1);
    var searchArray = __spreadArray(__spreadArray([], searchString1.split(' '), true), searchString2.split(' '), true);
    var preparedHaystack = haystack.trim().toLowerCase();
    var found = false;
    for (var _i = 0, searchArray_1 = searchArray; _i < searchArray_1.length; _i++) {
        var searchWord = searchArray_1[_i];
        if (!searchWord) {
            continue;
        }
        if (~preparedHaystack.indexOf(searchWord)) {
            found = true;
            break;
        }
    }
    return found;
}
export function GetArraysIntersection(arr1, arr2) {
    var result = new Set();
    arr1.forEach(function (item1) {
        arr2.forEach(function (item2) {
            if (item1 === item2) {
                result.add(item1);
            }
        });
    });
    return Array.from(result);
}
export function ApplyPercentage(n, percentage, round) {
    if (round === void 0) { round = true; }
    if (round) {
        return Math.round(n * percentage / 100);
    }
    return n * percentage / 100;
}
export function FormatMoney(n, options) {
    var sign = (options === null || options === void 0 ? void 0 : options.noCurrency) ? '' : ' ₽';
    if (isNaN(n)) {
        return "\u2014".concat(sign);
    }
    var money = n;
    if ((options === null || options === void 0 ? void 0 : options.percentage) !== undefined) {
        money = ApplyPercentage(money, options.percentage);
    }
    var roubles = Math.floor(money / 100);
    var cents = money % 100;
    if (cents === 0) {
        if (roubles < 10000) {
            return "".concat(roubles).concat(sign);
        }
        return "".concat(roubles.toLocaleString('ru-RU')).concat(sign);
    }
    var centsString = cents >= 10 ? String(cents) : '0' + cents;
    if (roubles < 10000) {
        return "".concat(roubles, ",").concat(centsString).concat(sign);
    }
    return "".concat(roubles.toLocaleString('ru-RU'), ",").concat(centsString).concat(sign);
}
export function GetTextForPaymentMonth(paymentMonth) {
    var months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
    var _a = paymentMonth.split('.'), month = _a[0], year = _a[1];
    return "".concat(months[parseInt(month, 10) - 1], " ").concat(year);
}
