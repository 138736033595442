import * as React from "react";
import { useState } from "react";
import { CheckCheckbox, CheckValue, CheckValueNotEmpty, CheckValueRegExp, ReduceStatuses } from "../../components/Form/Checkers";
import { DATE_CHECK_REGEXP, EMAIL_CHECK_REGEXP, PHONE_CHECK_REGEXP, TG_CHECK_REGEXP } from "../../functions";
import { JobsAPI } from "../../api/request/jobs/api";
import { InputLabel } from "../../components/Form/InputLabel";
import { SelectLabel } from "../../components/Form/SelectLabel";
import FileUploader from "../../components/Form/FileUploader";
import { FormAnnotation, FormError } from "../../components/Form";
import CheckLabel from "../../components/Form/CheckLabel";
import TextEditor from "../../components/Form/TextEditor";
import Attention from "../../components/Attention";
export default function JobForm(props) {
    var _a = useState(false), success = _a[0], setSuccess = _a[1];
    var _b = useState(false), submitting = _b[0], setSubmitting = _b[1];
    var _c = useState(''), lastNameError = _c[0], setLastNameError = _c[1];
    var _d = useState(''), firstNameError = _d[0], setFirstNameError = _d[1];
    var _e = useState(''), dateOfBirthError = _e[0], setDateOfBirthError = _e[1];
    var _f = useState(''), tgUserNameError = _f[0], setTgUserNameError = _f[1];
    var _g = useState(''), phoneError = _g[0], setPhoneError = _g[1];
    var _h = useState(''), emailError = _h[0], setEmailError = _h[1];
    var _j = useState(''), universityError = _j[0], setUniversityError = _j[1];
    var _k = useState(''), cityError = _k[0], setCityError = _k[1];
    var _l = useState(''), cvError = _l[0], setCvError = _l[1];
    var _m = useState(''), checksError = _m[0], setChecksError = _m[1];
    var _o = useState(''), serverError = _o[0], setServerError = _o[1];
    function SubmitForm(evt) {
        evt.preventDefault();
        setLastNameError('');
        setFirstNameError('');
        setDateOfBirthError('');
        setTgUserNameError('');
        setPhoneError('');
        setEmailError('');
        setUniversityError('');
        setCityError('');
        setCvError('');
        setChecksError('');
        setServerError('');
        var statuses = [];
        statuses.push(CheckValueNotEmpty('last_name', 'Введите фамилию', setLastNameError));
        statuses.push(CheckValueNotEmpty('first_name', 'Введите имя', setFirstNameError));
        statuses.push(CheckValueNotEmpty('date_of_birth', 'Введите дату рождения', setDateOfBirthError));
        statuses.push(CheckValueRegExp('date_of_birth', 'Некорректная дата рождения', DATE_CHECK_REGEXP, setDateOfBirthError));
        statuses.push(CheckValueNotEmpty('tg_username', 'Введите ваш Телеграм-никнейм', setTgUserNameError));
        statuses.push(CheckValueRegExp('tg_username', 'Некорректный Телеграм-никнейм', TG_CHECK_REGEXP, setTgUserNameError));
        statuses.push(CheckValue('tg_username', 'Уберите знак @ в никнейме', function (val) { return val[0] !== '@'; }, setTgUserNameError));
        statuses.push(CheckValueNotEmpty('phone', 'Введите номер телефона', setPhoneError));
        statuses.push(CheckValueRegExp('phone', 'Введите российский номер в заданном формате', PHONE_CHECK_REGEXP, setPhoneError));
        statuses.push(CheckValueNotEmpty('email', 'Введите почту', setEmailError));
        statuses.push(CheckValueRegExp('email', 'Некорректная почту', EMAIL_CHECK_REGEXP, setEmailError));
        statuses.push(CheckValueNotEmpty('university', 'Введите ваш университет', setUniversityError));
        statuses.push(CheckValueNotEmpty('city', 'Введите ваш город проживания', setCityError));
        statuses.push(CheckCheckbox('check1', 'Подтвердите согласие с соответствующими документами', setChecksError));
        statuses.push(CheckCheckbox('check2', 'Подтвердите согласие с соответствующими документами', setChecksError));
        // statuses.push(CheckValueNotEmpty('cv[]', 'Загрузите ваше резюме', setCvError));
        if (!ReduceStatuses(statuses)) {
            return;
        }
        setSubmitting(true);
        JobsAPI.SendCV(evt.target)
            .then(function (errors) {
            if (errors.length) {
                setServerError('Ошибка сервера: ' + errors.join(', '));
            }
            else {
                setSuccess(true);
            }
        })
            .finally(function () { return setSubmitting(false); });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "jobs-page__success-message", hidden: !success },
            React.createElement("div", { className: "jobs-page__success-icon" },
                React.createElement("span", { className: "material-symbols-outlined" }, "mark_email_read")),
            React.createElement("div", { className: "jobs-page__success-text" }, "\u0412\u0430\u0448\u00A0\u043E\u0442\u043A\u043B\u0438\u043A \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u043E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D. \u041C\u044B\u00A0\u0441\u043A\u043E\u0440\u043E \u043D\u0430\u00A0\u043D\u0435\u0433\u043E \u043E\u0442\u0432\u0435\u0442\u0438\u043C")),
        React.createElement("form", { onSubmit: SubmitForm, hidden: success },
            React.createElement("input", { type: "hidden", name: "job_id", value: props.chosenJob.id }),
            React.createElement(InputLabel, { title: "\u0424\u0430\u043C\u0438\u043B\u0438\u044F", name: "last_name", required: true, error: lastNameError }),
            React.createElement(InputLabel, { title: "\u0418\u043C\u044F", name: "first_name", required: true, error: firstNameError }),
            React.createElement(InputLabel, { title: "\u041E\u0442\u0447\u0435\u0441\u0442\u0432\u043E", name: "patronymic", annotation: "\u041E\u0441\u0442\u0430\u0432\u044C\u0442\u0435 \u043F\u043E\u043B\u0435 \u043F\u0443\u0441\u0442\u044B\u043C, \u0435\u0441\u043B\u0438 \u0443 \u0432\u0430\u0441 \u043D\u0435\u0442 \u043E\u0442\u0447\u0435\u0441\u0442\u0432\u0430" }),
            React.createElement(InputLabel, { title: "\u0414\u0430\u0442\u0430 \u0440\u043E\u0436\u0434\u0435\u043D\u0438\u044F", name: "date_of_birth", type: "date", required: true, error: dateOfBirthError }),
            React.createElement(SelectLabel, { title: "\u041F\u043E\u043B", name: "gender", required: true, options: [[1, "Мужской"], [0, "Женский"]] }),
            React.createElement(InputLabel, { title: "\u041D\u0438\u043A\u043D\u0435\u0439\u043C \u0432 \u0442\u0435\u043B\u0435\u0433\u0440\u0430\u043C", name: "tg_username", required: true, prefix: "t.me/", annotation: "\u041D\u0430\u043F\u0440\u0438\u043C\u0435\u0440, t.me/pavel_durov", error: tgUserNameError }),
            React.createElement(InputLabel, { title: "\u041D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430", name: "phone", required: true, annotation: "\u041D\u0430\u043F\u0440\u0438\u043C\u0435\u0440, +79450001122", error: phoneError }),
            React.createElement(InputLabel, { title: "\u0410\u0434\u0440\u0435\u0441 \u044D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u043E\u0439 \u043F\u043E\u0447\u0442\u044B", name: "email", required: true, annotation: "\u041D\u0430\u043F\u0440\u0438\u043C\u0435\u0440, pasha@yandex.ru", error: emailError }),
            React.createElement(InputLabel, { title: "\u0412\u0430\u0448\u0435 \u043E\u0431\u0440\u0430\u0437\u043E\u0432\u0430\u043D\u0438\u0435, \u0443\u043D\u0438\u0432\u0435\u0440\u0441\u0438\u0442\u0435\u0442", name: "university", required: true, error: universityError }),
            React.createElement(InputLabel, { title: "\u0413\u043E\u0440\u043E\u0434 \u043F\u0440\u043E\u0436\u0438\u0432\u0430\u043D\u0438\u044F", name: "city", required: true, error: cityError }),
            React.createElement(Attention, { status: "info" }, "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u043F\u0440\u0438\u043A\u0440\u0435\u043F\u0438\u0442\u0435 \u0440\u0435\u0437\u044E\u043C\u0435 \u043B\u0438\u0431\u043E\u00A0\u0432\u00A0\u043F\u043E\u043B\u0435 \u0444\u0430\u0439\u043B\u043E\u0432, \u043B\u0438\u0431\u043E\u00A0\u0441\u0441\u044B\u043B\u043A\u043E\u0439 \u0432\u00A0\u043F\u043E\u043B\u0435 \u0434\u043B\u044F\u00A0\u043A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u044F"),
            React.createElement("div", { className: "label" },
                React.createElement("span", null, "\u0424\u0430\u0439\u043B\u044B"),
                React.createElement(FileUploader, { name: "cv", multiple: true }),
                React.createElement(FormError, { text: cvError })),
            React.createElement("span", null, "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439"),
            React.createElement(TextEditor, { inputName: "comment_text" }),
            React.createElement(FormAnnotation, { text: "\u0417\u0434\u0435\u0441\u044C \u0432\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0443\u043A\u0430\u0437\u0430\u0442\u044C \u0434\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u0443\u044E \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044E \u0438\u043B\u0438 \u043F\u0440\u0438\u043A\u0440\u0435\u043F\u0438\u0442\u044C \u043F\u043E\u043B\u0435\u0437\u043D\u044B\u0435 \u0441\u0441\u044B\u043B\u043A\u0438" }),
            React.createElement(CheckLabel, { name: "check1" },
                "\u0414\u0430\u044E \u0441\u043E\u0433\u043B\u0430\u0441\u0438\u0435 \u043D\u0430\u00A0\u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0443 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445, \u0441\u043E\u0433\u043B\u0430\u0441\u043D\u043E ",
                React.createElement("a", { href: "/img/docs/politika_obrabotki_pd.pdf", target: "_blank", className: "text-link" }, "\u043F\u043E\u043B\u0438\u0442\u0438\u043A\u0435 \u0432\u00A0\u043E\u0442\u043D\u043E\u0448\u0435\u043D\u0438\u0438 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0438 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 (\u0426\u041F\u041C)"),
                " \u0438\u00A0",
                React.createElement("a", { href: "/img/docs/pk.pdf", target: "_blank", className: "text-link" }, "\u043F\u043E\u043B\u0438\u0442\u0438\u043A\u0435 \u0432\u00A0\u043E\u0442\u043D\u043E\u0448\u0435\u043D\u0438\u0438 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0438 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 (\u0410\u041F\u041E)"),
                "\u00A0",
                React.createElement("span", { className: "required" }, "*")),
            React.createElement(CheckLabel, { name: "check2" },
                "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u044E, \u0447\u0442\u043E \u043E\u0437\u043D\u0430\u043A\u043E\u043C\u0438\u043B\u0441\u044F \u0441\u00A0",
                React.createElement("a", { href: "/img/docs/pravila_raboty.pdf", target: "_blank", className: "text-link" }, "\u043F\u0440\u0430\u0432\u0438\u043B\u0430\u043C\u0438 \u0440\u0430\u0431\u043E\u0442\u044B"),
                ", \u0438\u00A0\u043F\u0440\u0438\u043D\u0438\u043C\u0430\u044E\u00A0\u0438x\u00A0",
                React.createElement("span", { className: "required" }, "*")),
            React.createElement(FormError, { text: checksError, style: { marginTop: -2, marginBottom: 10 } }),
            React.createElement("button", { disabled: submitting }, "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043E\u0442\u043A\u043B\u0438\u043A \u043D\u0430 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u044E"),
            React.createElement(FormError, { text: serverError }))));
}
